import Swal from "sweetalert2";
import axios from "../../axios";
import {  GET_SETTING, UPDATE_SETTING } from "../types";
import { setAlert } from "./alert";


export const getSettings = () => async (dispatch) => {
  try {
    const res = await axios.get("sett/get");
    if (res.data.code === 1) {
      dispatch({ type: GET_SETTING, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const updateSettings = (formData) => async (dispatch) => {
  try {
    const updatedFormData = {
      id: formData._id,
      ownerName: formData.ownerName,
      ownerEmail: formData.ownerEmail,
      ownerNo: formData.ownerNo,
      ownerWpNo: formData.ownerWpNo,
      returnDay: formData.returnDay,
      orderAmt: formData.orderAmt,
      termsCond: formData.termsCond,
      privacyPolicy: formData.privacyPolicy,
    };
    
    const res = await axios.post("sett/update", updatedFormData);
    if (res.data.code === 1) {
      dispatch({ type: UPDATE_SETTING, payload: res.data.data });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
      Swal.fire({
        title: 'Warning!',
        text: res.data.message,
        icon: 'warning',
      });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    Swal.fire({
      title: 'Error!',
      text: err.message,
      icon: 'error',
    });
  }
};
