import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import withRouter from "../../components/Common/withRouter";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { getSettings, updateSettings } from "../../store/actions/settings";
import { Select, Spin } from "antd";

const UserProfile = () => {
  document.title = "Settings | 09Mobile";

  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.list);
  const [initialValues, setInitialValues] = useState(null);

  const options = [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getSettings());
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (settings && settings.length > 0) {
      setInitialValues(settings[0]);
    }
  }, [settings]);

  const validationSchema = Yup.object({
    ownerName: Yup.string().required("Owner Name is required"),
    ownerEmail: Yup.string()
      .email("Invalid email address")
      .required("Owner Email is required"),
    ownerNo: Yup.number().required("Owner Phone is required"),
    ownerWpNo: Yup.number().required("Owner WhatsApp Number is required"),
    returnDay: Yup.number().required("Return Day is required"),
    orderAmt: Yup.number().required("Order Amount is required"),
    termsCond: Yup.string().required("Terms and Conditions are required"),
    privacyPolicy: Yup.string().required("Privacy Policy is required"),
    keywords: Yup.string().required("At least one keyword is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues || {},
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(updateSettings(values));
        await dispatch(getSettings());
        console.log("Settings updated:", values);
      } catch (error) {
        console.error("Failed to update settings:", error);
      }
    },
  });

  if (!initialValues) {
    return (
      <div>
        <Spin />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="09 Mobile Gallery" breadcrumbItem="Settings" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{settings[0].ownerName}</h5>
                        <p className="mb-1">email : {settings[0].ownerEmail}</p>
                        <p className="mb-0">
                          Advance order amount : {settings[0].orderAmt}
                        </p>
                        <p className="mb-0">
                          Contact No. : {settings[0].ownerNo}
                        </p>
                        <p className="mb-0">
                          Whatsapp No. : {settings[0].ownerWpNo}
                        </p>
                        <p className="mb-0">
                          Order Return Day : {settings[0].returnDay}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Update Owner Details</h4>

          <Form className="form-horizontal" onSubmit={formik.handleSubmit}>
            <Card>
              <CardBody>
                <div className="container">
                  {/* SETTINGS SECTION */}
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="form-group">
                        <Label className="form-label">Owner Name</Label>
                        <Input
                          name="ownerName"
                          value={formik.values.ownerName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          placeholder="Enter Owner Name"
                          type="text"
                          invalid={
                            formik.touched.ownerName && formik.errors.ownerName
                              ? true
                              : false
                          }
                        />
                        {formik.touched.ownerName &&
                          formik.errors.ownerName && (
                            <FormFeedback type="invalid">
                              {formik.errors.ownerName}
                            </FormFeedback>
                          )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <Label className="form-label">Owner Email</Label>
                        <Input
                          name="ownerEmail"
                          value={formik.values.ownerEmail}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          placeholder="Enter Owner Email"
                          type="email"
                          invalid={
                            formik.touched.ownerEmail &&
                            formik.errors.ownerEmail
                              ? true
                              : false
                          }
                        />
                        {formik.touched.ownerEmail &&
                          formik.errors.ownerEmail && (
                            <FormFeedback type="invalid">
                              {formik.errors.ownerEmail}
                            </FormFeedback>
                          )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <Label className="form-label">Owner Phone</Label>
                        <Input
                          name="ownerNo"
                          value={formik.values.ownerNo}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          placeholder="Enter Owner Phone"
                          type="number"
                          invalid={
                            formik.touched.ownerNo && formik.errors.ownerNo
                              ? true
                              : false
                          }
                        />
                        {formik.touched.ownerNo && formik.errors.ownerNo && (
                          <FormFeedback type="invalid">
                            {formik.errors.ownerNo}
                          </FormFeedback>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-4">
                      <div className="form-group">
                        <Label className="form-label">
                          Owner Whatsapp Number
                        </Label>
                        <Input
                          name="ownerWpNo"
                          value={formik.values.ownerWpNo}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          placeholder="Enter Owner Whatsapp Number"
                          type="number"
                          invalid={
                            formik.touched.ownerWpNo && formik.errors.ownerWpNo
                              ? true
                              : false
                          }
                        />
                        {formik.touched.ownerWpNo &&
                          formik.errors.ownerWpNo && (
                            <FormFeedback type="invalid">
                              {formik.errors.ownerWpNo}
                            </FormFeedback>
                          )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <Label className="form-label">Device Return Day</Label>
                        <Input
                          name="returnDay"
                          value={formik.values.returnDay}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          placeholder="Enter Return Day"
                          type="number"
                          invalid={
                            formik.touched.returnDay && formik.errors.returnDay
                              ? true
                              : false
                          }
                        />
                        {formik.touched.returnDay &&
                          formik.errors.returnDay && (
                            <FormFeedback type="invalid">
                              {formik.errors.returnDay}
                            </FormFeedback>
                          )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <Label className="form-label">
                          Advance Order Amount
                        </Label>
                        <Input
                          name="orderAmt"
                          value={formik.values.orderAmt}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          placeholder="Enter Advance order amount"
                          type="number"
                          invalid={
                            formik.touched.orderAmt && formik.errors.orderAmt
                              ? true
                              : false
                          }
                        />
                        {formik.touched.orderAmt && formik.errors.orderAmt && (
                          <FormFeedback type="invalid">
                            {formik.errors.orderAmt}
                          </FormFeedback>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* MAIL SECTION */}
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <div className="form-group">
                        <Label className="form-label">About Us</Label>
                        <Input
                          name="aboutUs"
                          value={formik.values.aboutUs}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          placeholder="Enter About Us"
                          type="text"
                          invalid={
                            formik.touched.aboutUs && formik.errors.aboutUs
                              ? true
                              : false
                          }
                        />
                        {formik.touched.aboutUs && formik.errors.aboutUs && (
                          <FormFeedback type="invalid">
                            {formik.errors.aboutUs}
                          </FormFeedback>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <Label className="form-label">Description</Label>
                        <Input
                          name="description"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          placeholder="Enter Description"
                          type="text"
                          invalid={
                            formik.touched.description &&
                            formik.errors.description
                              ? true
                              : false
                          }
                        />
                        {formik.touched.description &&
                          formik.errors.description && (
                            <FormFeedback type="invalid">
                              {formik.errors.description}
                            </FormFeedback>
                          )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <Label className="form-label">Address</Label>
                        <Input
                          name="address"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          placeholder="Enter Address"
                          type="text"
                          invalid={
                            formik.touched.address && formik.errors.address
                              ? true
                              : false
                          }
                        />
                        {formik.touched.address && formik.errors.address && (
                          <FormFeedback type="invalid">
                            {formik.errors.address}
                          </FormFeedback>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <Label className="form-label">Pincode</Label>
                        <Input
                          name="pinCode"
                          value={formik.values.pinCode}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          placeholder="Enter Pincode"
                          type="number"
                          invalid={
                            formik.touched.pinCode && formik.errors.pinCode
                              ? true
                              : false
                          }
                        />
                        {formik.touched.pinCode && formik.errors.pinCode && (
                          <FormFeedback type="invalid">
                            {formik.errors.pinCode}
                          </FormFeedback>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <Label className="form-label">Location Link</Label>
                        <Input
                          name="locUrl"
                          value={formik.values.locUrl}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          placeholder="Enter Location Link"
                          type="text"
                          invalid={
                            formik.touched.locUrl &&
                            formik.errors.locUrl
                              ? true
                              : false
                          }
                        />
                        {formik.touched.locUrl &&
                          formik.errors.locUrl && (
                            <FormFeedback type="invalid">
                              {formik.errors.locUrl}
                            </FormFeedback>
                          )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <Label className="form-label">Site Keywords</Label>
                        <Input
                          name="keywords"
                          value={formik.values.keywords}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          placeholder="Enter Site Keywords"
                          type="text"
                          invalid={
                            formik.touched.keywords &&
                            formik.errors.keywords
                              ? true
                              : false
                          }
                        />
                        {formik.touched.keywords &&
                          formik.errors.keywords && (
                            <FormFeedback type="invalid">
                              {formik.errors.keywords}
                            </FormFeedback>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    <Button type="submit" color="danger">
                      Update Setting Details
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>

            {/* POLICY SECTION */}
            <Card>
              <CardBody>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="form-group">
                      <Label className="form-label">Terms and Conditions</Label>
                      <ReactQuill
                        theme="snow"
                        value={formik.values.termsCond}
                        onChange={(content) =>
                          formik.setFieldValue("termsCond", content)
                        }
                        onBlur={() => formik.setFieldTouched("termsCond", true)}
                      />
                      {formik.touched.termsCond && formik.errors.termsCond && (
                        <div className="text-danger">
                          {formik.errors.termsCond}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    <Button type="submit" color="danger">
                      Update Terms and Condition
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="col-md-12">
                  <div className="form-group">
                    <Label className="form-label">Privacy Policy</Label>
                    <ReactQuill
                      theme="snow"
                      value={formik.values.privacyPolicy}
                      onChange={(content) =>
                        formik.setFieldValue("privacyPolicy", content)
                      }
                      onBlur={() =>
                        formik.setFieldTouched("privacyPolicy", true)
                      }
                    />
                    {formik.touched.privacyPolicy &&
                      formik.errors.privacyPolicy && (
                        <div className="text-danger">
                          {formik.errors.privacyPolicy}
                        </div>
                      )}
                  </div>
                </div>

                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update Policy Details
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
