import Swal from "sweetalert2";
import axios from "../../axios";
import {  ADD_SERIES, DELETE_SERIES, GET_SERIES, UPDATE_SERIES } from "../types";
import { setAlert } from "./alert";

export const addSeries = (formData) => async (dispatch) => {
  try {
    const res = await axios.post("series/add", formData);
    if (res.data.code === 1) {
      dispatch({ type: ADD_SERIES, payload: res.data.data });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
export const getSeries = () => async (dispatch) => {
  try {
    const res = await axios.get("series/get");
    console.log("brand :", res.data);
    if (res.data.code === 1) {
      dispatch({ type: GET_SERIES, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const updateSeries = (formData) => async (dispatch) => {
  try {
    const updatedFormData = {
      id: formData._id,
      ...(formData.name && { name: formData.name }),
      ...(formData.modelId && { modelId: formData.modelId }),
      ...(formData.status !== undefined && { status: formData.status }),
    };
    
    const res = await axios.post("series/update", updatedFormData);
    if (res.data.code === 1) {
      dispatch({ type: UPDATE_SERIES, payload: res.data.data });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
      Swal.fire({
        title: 'warning!',
        text: res.data.message,
        icon: 'warning',
    });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const deleteSeries = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const res = await axios.post(`series/delete/${id}`);
    if (res.data.code === 1) {
      dispatch({ type: DELETE_SERIES, payload: { id } });
      Swal.fire({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
    });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      dispatch(setAlert(res.data.message, "warning"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
