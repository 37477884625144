import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stringSorter } from "../../utils/functions.js";
import DataTable from "../../components/CustomDatatable/index.js";
import {
  Button,
  Card,
  Space,
  Typography,
  Breadcrumb,
  Row,
  Col,
  Popconfirm,
  Image,
} from "antd";
import {
  EditOutlined,
  HomeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Spinner } from "reactstrap";
import BrandsForm from "./BrandsForm.js";
import { enhanceColumnsWithFilters } from "../../components/ColumnFilters/index.js";
import { addBrand, deleteBrand, getBrands, updateBrand, updateBrandStatus } from "../../store/actions/brand.js";
import moment from "moment-timezone";
import CustomToggleSwitch from "../../components/CustomToggleSwitch.js";

const { Title } = Typography;

const Brands = () => {
  document.title = "Brands";
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const dispatch = useDispatch();
  const brand = useSelector((state) => state.brand.list);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        await dispatch(getBrands());
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [dispatch]);

  const handleCreate = async (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    
    if (values.image && values.image.length > 0) {
      formData.append('image', values.image[0].originFileObj);
    }
    
    if (selectedRecord) {
      formData.append('id', selectedRecord._id);
      await dispatch(updateBrand({ ...selectedRecord, ...values }));
      window.location.reload();
      await dispatch(getBrands());
    } else {
      await dispatch(addBrand(formData));
      await dispatch(getBrands());
    }
    setIsModalVisible(false);
  };

  // const handleCreate = async (values) => {
  //   const formData = new FormData();
  //   formData.append('name', values.name);
  
  //   // Check if an image file is selected and append it to FormData
  //   if (values.image && values.image.length > 0) {
  //     formData.append('image', values.image[0].originFileObj);
  //   }
  
  //   if (selectedRecord) {
  //     formData.append('id', selectedRecord._id);
  //     await dispatch(updateBrand(formData));
  //   } else {
  //     await dispatch(addBrand(formData));
  //   }
  //   await dispatch(getBrands());
  //   setIsModalVisible(false);
  // };
  

  // const handleCreate = async (values) => {
  //   const formData = new FormData();
  //   if (selectedRecord) {
  //     formData.append('id', selectedRecord._id);      
  //     await dispatch(updateBrand({ ...selectedRecord, ...values }));
  //     await dispatch(getBrands())
  //   } else {
  //     await dispatch(addBrand(values));
  //     await dispatch(getBrands())
  //   }
  //   setIsModalVisible(false);
  // };

  const handleDelete = async (id) => {
    await dispatch(deleteBrand(id));
    await dispatch(getBrands());
  };

  const handleChangeStatus = async (id, status) => {
    const formData = new FormData();
  
    formData.append('_id', id);
    formData.append('status', status);
    
    await dispatch(updateBrandStatus(id, status));
    await dispatch(getBrands());
  };

  // let columns = [
  //   {
  //     title: "Name",
  //     dataIndex: "name",
  //     key: "name",
  //     sorter: stringSorter("name"),
  //     sortDirections: ["ascend", "descend"],
  //   },
  //   {
  //     title: "Image",
  //     dataIndex: "image",
  //     key: "image",
  //     render: (image) =>
  //       image ? (
  //         <Image
  //           src={image}
  //           alt="Brand Image"
  //           preview={false}
  //           style={{ width: "100px", height: "auto", cursor: "pointer" }}
  //         />
  //       ) : (
  //         <span>No Image</span>
  //       ),
  //   },
  //   {
  //     title: "Action",
  //     key: "action",
  //     render: (_, record) => (
  //       <Space size="middle">
  //         <Button
  //           type="link"
  //           onClick={() => {
  //             setSelectedRecord(record);
  //             setIsModalVisible(true);
  //           }}
  //         >
  //           Edit
  //         </Button>
  //         <Popconfirm
  //           title="Are you sure you want to delete this record?"
  //           onConfirm={() => handleDelete(record._id)}
  //         >
  //           <Button type="link" danger>
  //             Delete
  //           </Button>
  //         </Popconfirm>
  //       </Space>
  //     ),
  //   },
  // ];

  const renderCell = (text) => (text ? text : 'N/A');
  let columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: stringSorter("name"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Image",
      dataIndex: "image", // Adjusted to match the correct data key
      key: "image",
      render: (image) =>
        image ? (
          <Image
            src={image}
            alt="Brand Image"
            preview={false}
            style={{ width: "100px", height: "auto", cursor: "pointer" }}
          />
        ) : (
          <span>No Image</span>
        ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.createdAt).isValid() && moment(b.createdAt).isValid() 
        ? moment(a.createdAt).unix() - moment(b.createdAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: stringSorter("createdByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => moment(date).isValid() ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "",
      sorter: (a, b) => moment(a.updatedAt).isValid() && moment(b.updatedAt).isValid() 
        ? moment(a.updatedAt).unix() - moment(b.updatedAt).unix() 
        : 0,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Updated By",
      dataIndex: "updatedByName",
      key: "updatedByName",
      sorter: stringSorter("updatedByName"),
      sortDirections: ["ascend", "descend"],
      render: renderCell,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <CustomToggleSwitch
          record={record}
          onChangeStatus={handleChangeStatus}
        />
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setSelectedRecord(record);
              setIsModalVisible(true);
            }}
          >
            <EditOutlined />
          </Button>
          {/* <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record._id)}
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];
  
  columns = enhanceColumnsWithFilters(columns);

  return (
    <div className="page-content">
      <Breadcrumb style={{ marginBottom: "16px" }}>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Brands</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                Manage Brands
              </Title>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedRecord(null);
                  setIsModalVisible(true);
                }}
              >
                Add Brands
              </Button>
            </Col>
          </Row>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <DataTable
              dataSource={brand.map((item) => ({
                ...item,
                key: item._id,
              }))}
              columns={columns}
            />
          )}
        </Space>
      </Card>
      <BrandsForm
        visible={isModalVisible}
        onCreate={handleCreate}
        onCancel={() => setIsModalVisible(false)}
        record={selectedRecord}
      />
    </div>
  );
};

export default Brands;