// ALERT
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

//ADMIN LOGIN
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_ERROR = 'AUTH_ERROR';

//ITEMS
export const ADD_ITEM = "ADD_ITEM"
export const UPDATE_ITEM = "UPDATE_ITEM"
export const GET_ITEMS = "GET_ITEMS"
export const GET_ITEM = "GET_ITEM"
export const DELETE_ITEM = "DELETE_ITEM"

//SALESMAN
export const ADD_SALESMAN="ADD_SALESMAN"
export const UPDATE_SALESMAN = "UPDATE_SALESMAN"
export const GET_SALESMANS = "GET_SALESMANS"
export const GET_SALESMAN="GET_SALESMAN"
export const DELETE_SALESMAN = "DELETE_SALESMAN"

//VISIT
export const GET_VISITS="GET_VISITS"

//COMPANY
export const ADD_COMPANY="ADD_COMPANY"
export const UPDATE_COMPANY="UPDATE_COMPANY"
export const GET_COMPANIES="GET_COMPANIES"
export const GET_COMPANY="GET_COMPANY"
export const DELETE_COMPANY="DELETE_COMPANY"
export const GET_CITIES="GET_CITIES"

//AMAZON ORDERS
export const GET_AMAZON_ORDERS = "GET_AMAZON_ORDERS"

//AMAZON ORDERS
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const ADD_CUST = "ADD_CUST"
export const ADD_CUSTOMERS = "ADD_CUSTOMERS"
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const DELETE_CUSTOMERS = "DELETE_CUSTOMERS"
export const GET_DASH_CUSTOMERS = "GET_DASH_CUSTOMERS"

//PRODUCTS
export const ADD_PRODUCTS="ADD_PRODUCTS"
export const UPDATE_PRODUCTS="UPDATE_PRODUCTS"
export const GET_PRODUCTS="GET_PRODUCTS"
export const GET_PRODUCT="GET_PRODUCT"
export const DELETE_PRODUCTS="DELETE_PRODUCTS"
export const DELETE_PRODUCTS_IMAGE="DELETE_PRODUCTS_IMAGE"
export const GET_DASH_PRODUCTS="GET_DASH_PRODUCTS"


//BRAND
export const ADD_BRAND="ADD_BRAND"
export const UPDATE_BRAND="UPDATE_BRAND"
export const GET_BRANDS="GET_BRANDS"
export const DELETE_BRAND="DELETE_BRAND"

//MODEL
export const ADD_MODEL="ADD_MODEL"
export const UPDATE_MODEL="UPDATE_MODEL"
export const GET_MODELS="GET_MODELS"
export const DELETE_MODEL="DELETE_MODEL"

//SERIES
export const ADD_SERIES="ADD_SERIES"
export const UPDATE_SERIES="UPDATE_SERIES"
export const GET_SERIES="GET_SERIES"
export const DELETE_SERIES="DELETE_SERIES"

//COUPON
export const ADD_COUPON="ADD_COUPON"
export const UPDATE_COUPON="UPDATE_COUPON"
export const GET_COUPONS="GET_COUPONS"
export const DELETE_COUPON="DELETE_COUPON"

//NOTIFY
export const ADD_NOTIFY="ADD_NOTIFY"
export const UPDATE_NOTIFY="UPDATE_NOTIFY"
export const GET_NOTIFY="GET_NOTIFY"
export const DELETE_NOTIFY="DELETE_NOTIFY"

//PRODUCT_ORDER
export const ADD_PRODUCT_ORDER="ADD_PRODUCT_ORDER"
export const UPDATE_PRODUCT_ORDER="UPDATE_PRODUCT_ORDER"
export const GET_PRODUCT_ORDER="GET_PRODUCT_ORDER"
export const DELETE_PRODUCT_ORDER="DELETE_PRODUCT_ORDER"
export const UPDATE_PRODUCT_ORDER_STATUS="UPDATE_PRODUCT_ORDER_STATUS"

//COUNTRY
export const ADD_COUNTRY="ADD_COUNTRY"
export const UPDATE_COUNTRY="UPDATE_COUNTRY"
export const GET_COUNTRYS="GET_COUNTRYS"
export const DELETE_COUNTRY="DELETE_COUNTRY"

//STATE
export const ADD_STATE="ADD_STATE"
export const UPDATE_STATE="UPDATE_STATE"
export const GET_STATES="GET_STATES"
export const DELETE_STATE="DELETE_STATE"

//CITY
export const ADD_CITY="ADD_CITY"
export const UPDATE_CITY="UPDATE_CITY"
export const GET_CITY="GET_CITY"
export const DELETE_CITY="DELETE_CITY"

//BANNER
export const ADD_BANNER="ADD_BANNER"
export const UPDATE_BANNER="UPDATE_BANNER"
export const GET_BANNER="GET_BANNER"
export const DELETE_BANNER="DELETE_BANNER"

//FAQ
export const ADD_FAQ="ADD_FAQ"
export const UPDATE_FAQ="UPDATE_FAQ"
export const GET_FAQ="GET_FAQ"
export const DELETE_FAQ="DELETE_FAQ"

//SETTING
export const UPDATE_SETTING="UPDATE_SETTING"
export const GET_SETTING="GET_SETTING"