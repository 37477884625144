import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getModels } from '../../store/actions/model';
import { getBrands } from '../../store/actions/brand';

const ModelsForm = ({ visible, onCreate, onCancel, record }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const BrandList = useSelector((state) => state.brand.list);
  const ModelList = useSelector((state) => state.model.list);

  const [selectedBrand, setSelectedBrand] = useState(null);

  useEffect(() => {
    // Fetch brands on component mount
    dispatch(getBrands());
  }, [dispatch]);

  useEffect(() => {
    // Fetch models when a brand is selected
    if (selectedBrand) {
      dispatch(getModels(selectedBrand));
    } else {
      dispatch(getModels()); // Ensure all models are fetched if brand is not selected
    }
  }, [selectedBrand, dispatch]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue(record);
      setSelectedBrand(record.brandId); // Set the initial selected brand
    } else {
      form.resetFields(); // Reset the form if there's no record
      setSelectedBrand(null); // Clear the selected brand
    }
  }, [record, form]);

  const handleBrandChange = (value) => {
    setSelectedBrand(value);
    form.setFieldsValue({ modelId: undefined }); // Clear the selected model when brand changes
  };

  // Filter models based on selected brand
  const filteredModels = ModelList.filter(model => model.brandId === selectedBrand);

  return (
    <Modal
      visible={visible}
      title={record ? "Update Model" : "Add Model"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={() => {
        onCancel();
        form.resetFields(); // Reset form fields when closing modal
        setSelectedBrand(null); // Reset selected brand
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onCreate(values);
            form.resetFields(); // Clear form fields after submission
            setSelectedBrand(null); // Reset selected brand
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        name="model_form"
      >
        <Form.Item
          name="brandId"
          label="Brand"
          rules={[{ required: true, message: 'Please select a brand!' }]}
        >
          <Select
            placeholder="Select a brand"
            onChange={handleBrandChange}
            value={selectedBrand}
          >
            {BrandList.map((brand) => (
              <Select.Option key={brand._id} value={brand._id}>
                {brand.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="modelId"
          label="Model"
          rules={[{ required: true, message: 'Please select a model!' }]}
        >
          <Select placeholder="Select a model" disabled={!selectedBrand}>
            {filteredModels.map((model) => (
              <Select.Option key={model._id} value={model._id}>
                {model.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter the model name!' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModelsForm;



// import React, { useEffect, useState } from 'react';
// import { Form, Input, Modal, Select } from 'antd';
// import { useDispatch, useSelector } from 'react-redux';
// import { getModels } from '../../store/actions/model';
// import { getBrands } from '../../store/actions/brand';

// const ModelsForm = ({ visible, onCreate, onCancel, record }) => {
//   const [form] = Form.useForm();
//   const dispatch = useDispatch();
//   const BrandList = useSelector((state) => state.brand.list);
//   const ModelList = useSelector((state) => state.model.list);

//   const [selectedBrand, setSelectedBrand] = useState(null);

//   useEffect(() => {
//     // Fetch brands and models on component mount
//     dispatch(getBrands());
//     dispatch(getModels());
//   }, [dispatch]);

//   useEffect(() => {
//     if (record) {
//       form.setFieldsValue(record);
//       setSelectedBrand(record.brandId); // Set the initial selected brand
//     } else {
//       form.resetFields(); // Reset the form if there's no record
//       setSelectedBrand(null); // Clear the selected brand
//     }
//   }, [record, form]);

//   const handleBrandChange = (value) => {
//     setSelectedBrand(value);
//   };

//   // Filter models based on selected brand
//   const filteredModels = ModelList.filter(model => model.brandId === selectedBrand);

//   return (
//     <Modal
//       visible={visible}
//       title={record ? "Update Series" : "Add Series"}
//       okText="Submit"
//       cancelText="Cancel"
//       onCancel={() => {
//         onCancel();
//         form.resetFields(); // Reset form fields when closing modal
//         setSelectedBrand(null); // Reset selected brand
//       }}
//       onOk={() => {
//         form
//           .validateFields()
//           .then(values => {
//             onCreate(values);
//             form.resetFields(); // Clear form fields after submission
//             setSelectedBrand(null); // Reset selected brand
//           })
//           .catch(info => {
//             console.log('Validate Failed:', info);
//           });
//       }}
//       width={800}
//     >
//       <Form
//         form={form}
//         layout="vertical"
//         name="model_form"
//       >
//         <Form.Item
//           name="brandId"
//           label="Brand"
//           rules={[{ required: true, message: 'Please select a brand!' }]}
//         >
//           <Select
//             placeholder="Select a brand"
//             onChange={handleBrandChange}
//           >
//             {BrandList.map((brand) => (
//               <Select.Option key={brand._id} value={brand._id}>
//                 {brand.name}
//               </Select.Option>
//             ))}
//           </Select>
//         </Form.Item>
//         <Form.Item
//           name="modelId"
//           label="Model"
//           rules={[{ required: true, message: 'Please select a model!' }]}
//         >
//           <Select placeholder="Select a model">
//             {filteredModels.map((model) => (
//               <Select.Option key={model._id} value={model._id}>
//                 {model.name}
//               </Select.Option>
//             ))}
//           </Select>
//         </Form.Item>
//         <Form.Item
//           name="name"
//           label="Name"
//           rules={[{ required: true, message: 'Please enter the model name!' }]}
//         >
//           <Input />
//         </Form.Item>
//       </Form>
//     </Modal>
//   );
// };

// export default ModelsForm;