import React, { useEffect } from 'react';
import { Form, Input, Modal, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getBrands } from '../../store/actions/brand';

const ModelsForm = ({ visible, onCreate, onCancel, record }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const brandList = useSelector((state) => state.brand.list);

  useEffect(() => {
    // Fetch brands on component mount
    dispatch(getBrands());
  }, [dispatch]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue(record);
    }
  }, [record, form]);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <Modal
      visible={visible}
      title={record ? "Update Model" : "Add Model"}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onCreate(values);
            form.resetFields();
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        name="model_form"
        initialValues={record}
      >
        <Form.Item
          name="brandId"
          label="Brand"
          rules={[{ required: true, message: 'Please select a brand!' }]}
        >
          <Select placeholder="Select a brand" onChange={handleChange}>
            {brandList.map((brand) => (
              <Select.Option key={brand._id} value={brand._id}>
                {brand.name}
              </Select.Option>
            ))}
          </Select>
          </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter the model name!' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModelsForm;


// import React, { useEffect } from 'react';
// import { Form, Input, Modal, Select } from 'antd';
// import { useDispatch, useSelector } from 'react-redux';
// import { getBrands } from '../../store/actions/brand';

// const ModelsForm = ({ visible, onCreate, onCancel, record }) => {
//   const [form] = Form.useForm();
//   const dispatch = useDispatch();
//   const brand = useSelector((state) => state.brand.list);

//   useEffect(() => {
//     // Fetch brands on component mount
//     dispatch(getBrands());
//   }, [dispatch]);

//   useEffect(() => {
//     form.setFieldsValue(record);
//   }, [record, form]);

//   const handleChange = (value) => {
//     console.log(`selected ${value}`);
//   };

//   const normFile = (e) => {
//     if (Array.isArray(e)) {
//       return e;
//     }
//     return e?.fileList;
//   };

//   return (
//     <Modal
//       visible={visible}
//       title={record ? "Update Model" : "Add Model"}
//       okText="Submit"
//       cancelText="Cancel"
//       onCancel={onCancel}
//       onOk={() => {
//         form
//           .validateFields()
//           .then(values => {
//             onCreate(values);
//             form.resetFields();
//           })
//           .catch(info => {
//             console.log('Validate Failed:', info);
//           });
//       }}
//       width={800}
//     >
//       <Form
//         form={form}
//         layout="vertical"
//         name="model_form"
//         initialValues={record}
//       >
//         <Form.Item
//           name="name"
//           label="Name"
//           rules={[{ required: true, message: 'Please enter the model name!' }]}
//         >
//           <Input />
//         </Form.Item>
//         <Form.Item
//           name="brandId"
//           label="Brand"
//           rules={[{ required: true, message: 'Please select a brand!' }]}
//         >
//           <Select placeholder="Select a brand">
//             {brand.map((brand) => (
//               <Select.Option key={brand._id} value={brand._id}>
//                 {brand.name}
//               </Select.Option>
//             ))}
//           </Select>
//         </Form.Item>
//       </Form>
//     </Modal>
//   );
// };

// export default ModelsForm;

// // const { Option } = Select;

// // const ProductsForm = ({ visible, onCreate, onCancel, record }) => {
// //   const [form] = Form.useForm();

// //   // Set initial values when the record changes
// //   React.useEffect(() => {
// //     form.setFieldsValue(record);
// //   }, [record, form]);

// //   return (
// //     <Modal
// //       visible={visible}
// //       title={record ? "Update Product" : "Add Product"}
// //       okText="Submit"
// //       cancelText="Cancel"
// //       onCancel={onCancel}
// //       onOk={() => {
// //         form
// //           .validateFields()
// //           .then(values => {
// //             onCreate(values);
// //             form.resetFields();
// //           })
// //           .catch(info => {
// //             console.log('Validate Failed:', info);
// //           });
// //       }}
// //     >
// //       <Form
// //         form={form}
// //         layout="vertical"
// //         name="product_form"
// //         initialValues={record}
// //       >
// //         <Form.Item
// //           name="name"
// //           label="Name"
// //           rules={[{ required: true, message: 'Please enter the product name!' }]}
// //         >
// //           <Input />
// //         </Form.Item>
// //         <Form.Item
// //           name="brandName"
// //           label="Brand"
// //           rules={[{ required: true, message: 'Please enter the brand name!' }]}
// //         >
// //           <Input />
// //         </Form.Item>
// //         <Form.Item
// //           name="modelName"
// //           label="Model"
// //           rules={[{ required: true, message: 'Please enter the model name!' }]}
// //         >
// //           <Input />
// //         </Form.Item>
// //         <Form.Item
// //           name="seriesName"
// //           label="Series"
// //           rules={[{ required: true, message: 'Please enter the series name!' }]}
// //         >
// //           <Input />
// //         </Form.Item>
// //         <Form.Item
// //           name="color"
// //           label="Color"
// //           rules={[{ required: true, message: 'Please enter the color!' }]}
// //         >
// //           <Input />
// //         </Form.Item>
// //         <Form.Item
// //           name="price"
// //           label="Price"
// //           rules={[{ required: true, message: 'Please enter the price!' }]}
// //         >
// //           <InputNumber min={0} style={{ width: '100%' }} />
// //         </Form.Item>
// //         <Form.Item
// //           name="storage"
// //           label="Storage"
// //           rules={[{ required: true, message: 'Please enter the storage!' }]}
// //         >
// //           <Input />
// //         </Form.Item>
// //         <Form.Item
// //           name="network"
// //           label="Network"
// //           rules={[{ required: true, message: 'Please enter the network!' }]}
// //         >
// //           <Input />
// //         </Form.Item>
// //         <Form.Item
// //           name="osNo"
// //           label="OS"
// //           rules={[{ required: true, message: 'Please enter the OS!' }]}
// //         >
// //           <Input />
// //         </Form.Item>
// //         <Form.Item
// //           name="inStock"
// //           label="In Stock"
// //           valuePropName="checked"
// //         >
// //           <Input type="checkbox" />
// //         </Form.Item>
// //       </Form>
// //     </Modal>
// //   );
// // };

// // export default ProductsForm;