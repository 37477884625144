import {
    DELETE_CUSTOMERS,
    GET_CUSTOMERS,
    ADD_CUST,
    ADD_CUSTOMERS,
    UPDATE_CUSTOMER,
    GET_DASH_CUSTOMERS
} from "../types";
import {setAlert} from "./alert";
import axios from "../../axios";
import Swal from 'sweetalert2';

export const getCustomers = () => async (dispatch) => {
    try {
        const res = await axios.get("user/getAll");
        if (res.data.code === 1) {
            dispatch({type: GET_CUSTOMERS, payload: res.data.data});
        }
    } catch (err) {
        console.log(err)
        const errorMessage = err.response?.data?.message || 'An unexpected error occurred';

        Swal.fire({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
        });
    }
};

export const getDashboardCustomers = (status, fromDate, toDate) => async (dispatch) => {
    try {
        const url = `user/getAll?status=${status}&fromDate=${fromDate}&toDate=${toDate}`;
        const res = await axios.get(url);
        if (res.data.code === 1) {
            dispatch({ type: GET_DASH_CUSTOMERS, payload: res.data.data });
        }
    } catch (err) {
        console.log(err);
        const errorMessage = err.response?.data?.message || 'An unexpected error occurred';
        Swal.fire({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
        });
    }
};

// export const getDashboardCustomers = (fromDate, toDate) => async (dispatch) => {
//     try {
//         const url = `user/getAll?status=1&fromDate=${fromDate}&toDate=${toDate}`;
//         const res = await axios.get(url);
//         if (res.data.code === 1) {
//             dispatch({ type: GET_CUSTOMERS, payload: res.data.data });
//         }
//     } catch (err) {
//         console.log(err);
//         const errorMessage = err.response?.data?.message || 'An unexpected error occurred';

//         Swal.fire({
//             title: 'Error!',
//             text: errorMessage,
//             icon: 'error',
//         });
//     }
// };

// export const addCustomer = (formData) => async (dispatch) => {
//     try {
//       const res = await axios.post("user/add", formData);
//       console.log("formData add :", formData);
      
//       if (res.data.code === 1) {
//         dispatch({ type: ADD_CUST, payload: res.data.data });
//         Swal.fire({
//           title: 'Success!',
//           text: res.data.message,
//           icon: 'success',
//       });
//         dispatch(setAlert(res.data.message, "success"));
//       } else {
//         Swal.fire({
//             title: 'warning!',
//             text: res.data.message,
//             icon: 'warning',
//         });
//         dispatch(setAlert(res.data.message, "warning"));
//       }
//     } catch (err) {
//       dispatch(setAlert(err.message, "danger"));
//     }
//   };

export const addCustomer = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("user/add", formData);
        console.log("formData add :", formData);
        
        if (res.data.code === 1) {
            dispatch({ type: ADD_CUST, payload: res.data.data });
            Swal.fire({
                title: 'Success!',
                text: res.data.message,
                icon: 'success',
            });
        } else {
            Swal.fire({
                title: 'Warning!',
                text: res.data.message,
                icon: 'warning',
            });
        }
    } catch (err) {
        const errorMessage = err.response?.data?.message || 'An unexpected error occurred';

        Swal.fire({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
        });
    }
};

export const getStaff = () => async (dispatch) => {
    try {
        const res = await axios.get("user/getAll?role=2");
        if (res.data.code === 1) {
            dispatch({type: GET_CUSTOMERS, payload: res.data.data});
        }
    } catch (err) {
        console.log(err)
    }
};

export const addStaff = (formData) => async (dispatch) => {
    try {
      const res = await axios.post("staff/add", formData);
      if (res.data.code === 1) {
        dispatch({ type: ADD_CUSTOMERS, payload: res.data.data });
        Swal.fire({
          title: 'Success!',
          text: res.data.message,
          icon: 'success',
      });
        dispatch(setAlert(res.data.message, "success"));
      } else {
        // dispatch(setAlert(res.data.message, "warning"));
        Swal.fire({
            title: 'Warning!',
            text: res.data.message,
            icon: 'warning',
        });
      }
    } catch (err) {
    //   dispatch(setAlert(err.message, "danger"));
      const errorMessage = err.response?.data?.message || 'An unexpected error occurred';

        Swal.fire({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
        });
    }
  };

export const updateCustomers = (formData) => async (dispatch) => {
    try {
        // const updatedFormData = { ...formData, id: formData._id };
        console.log("formdata :", formData);
        
        const updatedFormData = {
            id: formData._id,
            ...(formData.name && { name: formData.name }),
            ...(formData.email && { email: formData.email }),
            ...(formData.mobile && { mobile: formData.mobile }),
            ...(formData.phone && { phone: formData.phone }),
            ...(formData.cityId && { cityId: formData.cityId }),
            ...(formData.stateId && { stateId: formData.stateId }),
            ...(formData.countryId && { countryId: formData.countryId }),
            ...(formData.role && { role: formData.role }),
            ...(formData.status && { status: formData.status }),
        };
        console.log("updatedFormData :", updatedFormData);
        const res = await axios.post("user/update", updatedFormData);
        
        
        if (res.data.code === 1) {
            dispatch({type: UPDATE_CUSTOMER, payload: res.data.data});
            Swal.fire({
                title: 'Success!',
                text: res.data.message,
                icon: 'success',
            });
        } else {
            Swal.fire({
                title: 'Warning!',
                text: res.data.message,
                icon: 'warning',
            });
        }
    } catch (err) {
        // dispatch(setAlert(err.message, 'danger'));
        const errorMessage = err.response?.data?.message || 'An unexpected error occurred';

        Swal.fire({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
        });
    }
};

export const updateCustomerStatus = (id, status) => async (dispatch) => {
    try {
        // const updatedFormData = { ...formData, id: formData._id };
        // console.log("formdata updateCustomerStatus:", id, status);
        
        const res = await axios.post("user/update", {id, status}, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
        
        
        if (res.data.code === 1) {
            dispatch({type: UPDATE_CUSTOMER, payload: res.data.data});
            Swal.fire({
                title: 'Success!',
                text: res.data.message,
                icon: 'success',
            });
            dispatch(setAlert(res.data.message, 'success'));
        } else {
            dispatch(setAlert(res.data.message, "warning"));
            Swal.fire({
                title: 'Warning!',
                text: res.data.message,
                icon: 'warning',
            });
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
        const errorMessage = err.response?.data?.message || 'An unexpected error occurred';

        Swal.fire({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
        });
    }
};

export const updateStaffStatus = (id, status) => async (dispatch) => {
    try {
        // const updatedFormData = { ...formData, id: formData._id };
        // console.log("formdata updateCustomerStatus:", id, status);
        
        const res = await axios.post("user/update", {id, status}, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
        
        
        if (res.data.code === 1) {
            dispatch({type: UPDATE_CUSTOMER, payload: res.data.data});
            Swal.fire({
                title: 'Success!',
                text: res.data.message,
                icon: 'success',
            });
            dispatch(setAlert(res.data.message, 'success'));
        } else {
            dispatch(setAlert(res.data.message, "warning"));
            Swal.fire({
                title: 'Warning!',
                text: res.data.message,
                icon: 'warning',
            });
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
        const errorMessage = err.response?.data?.message || 'An unexpected error occurred';

        Swal.fire({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
        });
    }
};

export const deleteCustomers = (_id) => async (dispatch) => {
    try {
        if (!_id) {
            dispatch(setAlert('Please select any record', 'warning'));
            return false;
        }
        const res = await axios.post(`user/delete/${_id}`);
        if (res.data.code === 1) {
            dispatch({type: DELETE_CUSTOMERS, payload: {_id}});
            dispatch(setAlert(res.data.message, 'success'));
            Swal.fire({
                title: 'Success!',
                text: res.data.message,
                icon: 'success',
            });
        } else {
            dispatch(setAlert(res.data.message, "warning"));
            Swal.fire({
                title: 'Warning!',
                text: res.data.message,
                icon: 'warning',
            });
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
        const errorMessage = err.response?.data?.message || 'An unexpected error occurred';

        Swal.fire({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
        });
    }
};





